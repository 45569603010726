<template>
  <div class="search_filter_wrap">
    <div class="search_item_wrap">
      <div class="filter_category_box">
        <el-select id="category" v-model="searchOption" class="my_filter select"
                   popper-class="category_select"
                   :popper-append-to-body="false">
          <el-option :value="'ina'" :label="$t('subject')"></el-option>
          <el-option :value="'hash'" :label="$t('hashtag')"></el-option>
          <el-option :value="'orni'" :label="$t('k_creator')"></el-option>
          <el-option :value="'prni'" :label="$t('owner')"></el-option>
          <el-option :value="'cuni'" :label="$t('curator')"></el-option>
        </el-select>
      </div>
      <div class="search_input">
        <el-input :placeholder="$t('st')" v-model="searchInput"
                  @input="setData('searchInput',searchInput)"
                  @keypress.enter="setFilterFunc(searchOption,searchInput)"/>
        <button class="search_btn" @click="setFilterFunc(searchOption,searchInput)"><span>{{$t('search')}}</span>
          <img src="@/assets/image/main/search_btn.png"></button>
      </div>
      <div class="end_filter">
        <!--        <button class="filter_btn two" @click="changeCheck('filterEndCheck')"></button>-->
        <el-popover
            popper-class="top_500"
            placement=""
            width="435"
            trigger="click"
            :open-delay="300"
            :offset="0"
            :append-to-body="false"
            :popper-options="{
              boundariesElement: 'body',
              gpuAcceleration: true,
              positionFixed: true,
              preventOverflow: true,
            }">
          <el-button slot="reference" class="filter_btn two" @click="changeCheck('filterEndCheck')"></el-button>
          <div class="popup_tail"></div>
          <div class="popup_bg">
            <div class="popup_inner">
              <div class="inner_top"><span class="title">{{ $t('filter') }}</span></div>
              <div class="inner_middle">
                <div class="sub_title">{{$t('status')}}</div>
                <div class="status_wrap">
                  <el-checkbox v-model="auctionCheck" @click="changeCheck('auctionCheck')">
                    {{$t('auction')}}
                  </el-checkbox>
                  <el-checkbox v-model="licenseCheck" @click="changeCheck('licenseCheck')">
                    {{$t('license_sell')}}
                  </el-checkbox>
                  <el-checkbox v-model="sellCheck" @click="changeCheck('sellCheck')">
                    {{$t('fix_selling')}}
                  </el-checkbox>
                </div>
                <div class="sub_title">{{$t('edition')}}</div>
                <div class="edition_wrap">
                  <el-checkbox v-model="edtitionCheck" @click="changeCheck('edtitionCheck')">
                    {{$t('edition')}}
                  </el-checkbox>
                  <el-checkbox v-model="singleCheck" @click="changeCheck('singleCheck')">
                    {{$t('single')}}
                  </el-checkbox>
                </div>
                <div class="sub_title">{{$t('type')}}</div>
                <div class="type_wrap">
                  <el-checkbox v-model="digitalCheck" @click="changeCheck('digitalCheck')">
                    {{$t('digital')}}
                  </el-checkbox>
                  <el-checkbox v-model="realCheck" @click="changeCheck('realCheck')">
                    {{$t('real')}}
                  </el-checkbox>
                </div>
                <div class="sub_title">{{$t('price2')}}</div>
                <div class="price_wrap">
                  <el-input v-model="inputPrice1" class="el_price_input el_input_num" type="number"
                            @input="setData('inputPrice1',inputPrice1)"/>
                  <span> Mg</span>
                  <span class="first_input_step">~</span>
                  <el-input v-model="inputPrice2" class="el_price_input el_input_num" type="number"
                            @input="setData('inputPrice2',inputPrice2)"/>
                  <span> Mg</span>
                </div>
              </div>
            </div>
          </div>
          <div class="wrapper_bottom" @click="changeCheck('filterEndCheck')">
            <button class="">{{$t('apply2')}}</button>
          </div>
        </el-popover>
      </div>
      <!--      <div class="filter_sort_box">
              <el-select id="sort_select" v-model="sortOption" class="sort_filter select"
                         @change="sortChange(sortOption)">
                <el-option :value="'i.updated_at'" :label="$t('b_update')"></el-option>
                <el-option :value="'im_hit'" :label="$t('b_enter')"></el-option>
                <el-option :value="'im_like'" :label="$t('b_like')"></el-option>
                <el-option :value="'priceDesc'" :label="$t('priceDesc')"></el-option>
                <el-option :value="'priceAsc'" :label="$t('priceAsc')"></el-option>
              </el-select>
            </div>-->


    </div>
  </div>
</template>

<script>
export default {
  name: "CompetitionFilterDefaultLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
      filterData: {default: () => {}},
  },
  data() {
    return{
      searchOption: 'ina',
      sortOption: 'i.updated_at',
      searchInput: '',
      filterEndCheck: false,

      auctionCheck: false,
      licenseCheck: false,
      sellCheck: false,

      edtitionCheck: false,
      singleCheck: false,

      digitalCheck: false,
      realCheck: false,

      inputPrice1: '',
      inputPrice2: '',
    }
  },
  beforeRouterEnter() {},
  created() {
    this.setterFilterDataInData();
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    setterFilterDataInData() {
      let classnames = Object.keys(this.filterData);
      classnames.forEach(classname => {
        let obj = this.filterData[classname];
        let type = filterConfig[obj.key].type;
        if (this.hasOwnProperty(classname)) {
          // this[classname] =
          if (type == 'query') {
            this[classname] = obj.query;
            if (this.hasOwnProperty(classname + 'Option')) {
              this[classname + 'Option'] = obj.key;
            }
          } else if (type === 'between') {
            this[classname] = [obj.start, obj.end];
          } else if (type === 'match') {
            if (isNaN(obj.value)) {
              this[classname] = obj.value;
            } else {
              this[classname] = Number(obj.value);
            }

          } else if (type === 'terms') {
            this[classname] = obj.terms;
          }

        }
      })
    },
    sortChange(data) {
      if (data === 'priceAsc') {
        this.setFilterFunc('obt', 'i_price');
        this.setFilterFunc('obs', 'asc');
      } else if (data === 'priceDesc') {
        this.setFilterFunc('obt', 'i_price');
        this.setFilterFunc('obs', 'desc');
      } else {
        this.setFilterFunc('obt', data);
        this.setFilterFunc('obs', 'desc');
      }
    },
    setFilterFunc(key, value) {
      this.setFilter(key, value);
    },
    setData(key, value) {
      this[key] = value;
    },
    openModal(name) {
      this.$modal.show(name);
    },
    changeCheck(dataName) {
      this[dataName] = !this[dataName];
      if(this[dataName] ) {
        this[dataName] = true;
      } else {
        this[dataName] = false;
      }
      if(this[dataName]) {
        this.btnCheck = false;
      }
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
